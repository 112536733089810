let config = Object.assign({});
const production = require("./env.config").production;

Object.defineProperties(config, {
  apiTimeout: {
    value: production ? 10 * 1000 : 20 * 1000, // 10 or 20 sec
    writable: false,
  },
  serviceUrl: {
    value: production
      ? "https://xoapi.maxmobility.in/"
      : "http://192.168.0.104:9009",
    writable: false,
  },
  refreshTimeout: {
    value: production ? 60 : 10, //in minutes
    writable: false,
  },
  xApiToken: {
    value: "1234567809",
    writable: false,
  },
  knowMoreLink: {
    value: "http://xoserum.com/",
    writable: false,
  },
  feedbackCommentCharLimit: {
    value: 300,
    writable: false,
  },
  myownnCustomerSupport: {
    value: "022-40543232",
    writable: false,
  },
  bdUrl: {
    value: "https://www.bluedart.com/?",
    writable: false,
  },
  seriesName: {
    value: "XO",
    writable: false,
  },
});
Object.seal(config);

export default config;
